import React from 'react'
import { graphql , Link} from 'gatsby'
import Layout from '../components/layout.jsx'
import CategoryList from '../components/categorylist.jsx'
import { makeStyles } from '@material-ui/core/styles';
import {convertJPTime} from '../utils/convert_jp_time.js'
import PaginationLink from '../components/paginationlink.jsx'
import Seo from '../components/seo.jsx'
const use_style = makeStyles((theme) => ({
  readingButton :  {
    textAlign: 'right'
  },
  blogTitleHead : {
    marginBottom : '10px'
  },
  blogDate: {
    marginBottom : '10px'
  }

}))

const indexPage = ({ data, pageContext }) => {
  const classes = use_style();
  const { group } = pageContext
  return (
  <Layout title={data.site.siteMetadata.title}>
      <Seo
        title={data.site.siteMetadata.titleAlt}
        description={data.site.description || ' '}
        image={data.site.logo}
        pathname={data.site.siteUrl}
      />
      {group.map(({ node }) => (
        <div key={node.id}>
          <h2 className={classes.blogTitleHead}>{node.frontmatter.title}</h2>
          <p className={classes.blogDate}>{
           convertJPTime(node.frontmatter.date)
          }</p>
          <div>
            <CategoryList categories={node.frontmatter.categories || []} />
          </div>
          <p>{node.excerpt}</p>
          <div className={classes.readingButton}>
            <Link to={node.frontmatter.path} >
              続きを読む
            </Link>
          </div>
        </div>
    ))}
    <PaginationLink pageContext={pageContext}/>
  </Layout>
)}

export const query = graphql`
  query {
    site {
        siteMetadata {
          title
          titleAlt
          description
          logo
          siteUrl
      }
    }
    allMarkdownRemark(
      filter: { 
        frontmatter: { 
          status: { eq: "publish" } 
        } 
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    )  {
      edges {
        node {
          id
          excerpt(pruneLength: 50, truncate: true)
          frontmatter {
            title
            date
			      path
            categories
          }
        }
      }
    }
  }
`
export default indexPage;