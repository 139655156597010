import React from 'react'
import Link from 'gatsby-link'
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

const IndexPage = ({ pageContext }) => {
  const { index, pageCount } = pageContext
  return (
    <div>
      <Pagination
          page={index}
          count={pageCount}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/${item.page === 1 ? '' : item.page}`}
              {...item}
            />
          )}
        />
    </div>
    
  )
}
export default IndexPage